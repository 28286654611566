import { Component, Inject, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EncrDecrServiceService } from '../../../services/encr-decr-service.service';
import { SystemadminService } from '../../../services/systemadmin.service';
import { DynamicInstance, MyLicense } from '../../../model/systemadmin';
import { GeneralMaster } from '../../../model/common';
import { MastersService } from '../../../services/masters.service';
declare let $: any;
import Swal from 'sweetalert2';
import { data } from 'jquery';
@Component({
  selector: 'app-clientmanagement',
  templateUrl: './clientmanagement.component.html',
  styleUrls: ['./clientmanagement.component.css']
})
export class ClientmanagementComponent implements OnInit {
    ActiveValues: Status[] = [
        { value: '1', viewValue: 'YES' },
        { value: '2', viewValue: 'NO' },
    ];
    constructor(private router: Router, private route: ActivatedRoute, private service: MastersService, private sa: SystemadminService, private ES: EncrDecrServiceService, private fb: FormBuilder) { }
    ClientForm: FormGroup;
    CompanyID = 0;
    LicenseTypeItem: GeneralMaster[];
    InstanceTypeItem: GeneralMaster[];
    DynamicNumberRange: Array<DynamicInstance> = [];
    val: any = {};
    NID = null;
    HDArrayIndex = '';
    //UpdateID = 0;
    EdiNID = 0;
    chkindex = "0";
    RangeID = 1;
    ValidID = 0;
    updateID = 0;
    EditID = 0;
    ngOnInit(): void {
        $('.my-select').select2();
        this.createForm();

        var queryString = new Array();

        this.route.queryParams.subscribe(params => {
            var Parameter = this.ES.get(localStorage.getItem("EncKey"), params['encrypted']);
            var KeyPara = Parameter.split(',');
            for (var i = 0; i < KeyPara.length; i++) {
                var key = KeyPara[i].split(':')[0];
                var value = KeyPara[i].split(':')[1];
                queryString[key] = value;
            }
            if (queryString["ID"] != null) {
                this.CompanyID = queryString["ID"];

            }
         
            this.createForm();
            
        });
        this.onInitalBinding();
        this.BindExistingRecords(this.CompanyID);
    }

    createForm() {

        this.ClientForm = this.fb.group({
            ID: 0,
            ClientNumber: '',
            LicenseNumber: '',
            LicenseType:0,
            TypeID: 0,
            Description: '',
            Url: '',
            NoOfUsers: '',
            StartDate: '',
            EndDate: '',
            ActiveID: 0,
            Notes:'',

        });


    }
    onInitalBinding() {

        this.sa.GetLicenseType(this.ClientForm.value).subscribe(data => {
            this.LicenseTypeItem = data;
        });
        this.sa.GetInstanceType(this.ClientForm.value).subscribe(data => {
            this.InstanceTypeItem = data;
        });

    }


    AddRecord() {
        var validation = "";

        var ddlInstanceType = $('#ddlInstanceType').val();
        if (ddlInstanceType == null) {
            validation += "<span style='color:red;'>*</span> <span>Please Select Type</span></br>"
        }
        if ($('#txtDescription').val() == "") {
            validation += "<span style='color:red;'>*</span> <span>Please Enter Description</span></br>"
        }
        if ($('#txtUrl').val() == "") {
            validation += "<span style='color:red;'>*</span> <span>Please Enter Url</span></br>"
        }
        if ($('#txtNoOfUsers').val() == "") {
            validation += "<span style='color:red;'>*</span> <span>Please Enter No Of Users</span></br>"
        }
        if ($('#txtStartDate').val() == "") {
            validation += "<span style='color:red;'>*</span> <span>Please Enter Start Date</span></br>"
        }
        if ($('#txtEndDate').val() == "") {
            validation += "<span style='color:red;'>*</span> <span>Please Enter End Date</span></br>"
        }
        var ddlActive = $('#ddlActive').val();
        if (ddlActive == null) {
            validation += "<span style='color:red;'>*</span> <span>Please Select Active</span></br>"
        }

      
        //if (this.NID == 0) {
        //     for (var i = 0; i < this.DynamicNumberRange.length; i++) {

        //    if (this.DynamicNumberRange[i].TypeID == $('#ddlInstanceType').val()
        //      && (this.NID == null || this.NID != null)) {
        //        validation += "<span style='color:red;'>*</span> <span>Instance Type Already Exists</span></br>"
        //    }
        //}
        //}

       
        for (var i = 0; i < this.DynamicNumberRange.length; i++) {

            
            if (this.DynamicNumberRange[i].TypeID == $('#ddlInstanceType').val()
                && (this.NID == null)) {
                        validation += "<span style='color:red;'>*</span> <span>Instance Type Already Exists</span></br>"
                    }
                
            }
        


        if (validation != "") {
            this.EditID = 1;
            this.updateID = 0;
            Swal.fire(validation);
            return false;
        }
        var NIDValue;

        this.updateID = 1;
        this.EditID = 0;

        NIDValue = (this.NID == null) ? 0 : this.NID;
        this.val = {
            NID: NIDValue,
            TypeID: $("#ddlInstanceType").val(),
            Type: $("#ddlInstanceType option:selected").text(),
            Description: $("#txtDescription").val(),
            Url: $("#txtUrl").val(),
            NoOfUsers: $("#txtNoOfUsers").val(),
            StartDate: $("#txtStartDate").val(),
            EndDate: $("#txtEndDate").val(),
            ActiveID: $("#ddlActive").val(),
            Active: $("#ddlActive option:selected").text(),

        };

        if (this.HDArrayIndex != null && this.HDArrayIndex.length != 0) {
            this.DynamicNumberRange[this.HDArrayIndex] = this.val;
        } else {
            this.DynamicNumberRange.push(this.val);
        }

        $('#ddlInstanceType').val(0).trigger("change");
        $("#txtDescription").val("").trigger("change");
        $("#txtUrl").val("").trigger("change");
        $("#txtNoOfUsers").val("").trigger("change");
        $("#txtStartDate").val("").trigger("change");
        $("#txtEndDate").val("").trigger("change");
        $('#ddlActive').val(0).trigger("change");

        this.NID = 0;
        this.HDArrayIndex = '';
    } 


    OnClickRadio(index, NID) {

        this.EdiNID = NID;
        this.chkindex = index;
    }
    EditRecord() {
        var checked = $("#datatable input[type=radio]:checked").length;
        if (this.DynamicNumberRange.length != 0) {
            if (checked == 0) {
                Swal.fire("Please Select Any one");
            }
            else {
                
                var NIDV = 0;
                NIDV = this.EdiNID;

                this.DynamicNumberRange[this.chkindex].NID = NIDV;
                this.HDArrayIndex = this.chkindex;
                this.NID = this.DynamicNumberRange[this.chkindex].NID;
                this.EdiNID = this.DynamicNumberRange[this.chkindex].NID;


                $("#ddlInstanceType").select2().val(this.DynamicNumberRange[this.chkindex].TypeID).trigger("change");
                $("#txtDescription").val(this.DynamicNumberRange[this.chkindex].Description);
                $("#txtUrl").val(this.DynamicNumberRange[this.chkindex].Url);
                $("#txtNoOfUsers").val(this.DynamicNumberRange[this.chkindex].NoOfUsers);
                $("#txtStartDate").val(this.DynamicNumberRange[this.chkindex].StartDate);
                $("#txtEndDate").val(this.DynamicNumberRange[this.chkindex].EndDate);
                $("#ddlActive").select2().val(this.DynamicNumberRange[this.chkindex].ActiveID).trigger("change");
            }
        }
        else {
            Swal.fire("No Records Found");
        }
    }



    DeleteRecord(chkind) {

        chkind = this.chkindex;
        var checked = $("#datatable input[type=radio]:checked").length;

        if (checked == 0) {
            Swal.fire("Please check atleast one ");
        }
        else {

            if (this.ValidID == 1) {
                Swal.fire({
                    title: 'Records Entered will not be Saved, Are you sure want to Delete ?',
                    showDenyButton: true,
                    confirmButtonText: 'YES',
                    cancelButtonText: 'NO',
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.updateID = 0;

                        this.ClientForm.value.NID = this.EdiNID;
                        /*this.ClientForm.value.TerminalID = this.TerminalID;*/

                        if (this.ClientForm.value.NID != "") {
                            this.sa.DeleteInstanceDtls(this.ClientForm.value).subscribe(Data => {
                                //this.ExistingvaluesBind(this.TerminalID);
                                //Swal.fire("Deleted Successfully");

                                Swal.fire({
                                    title: 'Deleted Successfully',
                                    showDenyButton: false,
                                    confirmButtonText: 'OK',
                                    allowOutsideClick: false,
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        $('#ddlInstanceType').val(0).trigger("change");
                                        $("#txtDescription").val("").trigger("change");
                                        $("#txtUrl").val("").trigger("change");
                                        $("#txtNoOfUsers").val("").trigger("change");
                                        $("#txtStartDate").val("").trigger("change");
                                        $("#txtEndDate").val("").trigger("change");
                                        $('#ddlActive').val(0).trigger("change");
                                        this.BindExistingRecords(this.CompanyID);
                                    } else {

                                    }
                                });
                            },
                                (error: HttpErrorResponse) => {
                                    Swal.fire(error.message)
                                });
                        }
                        else {
                            this.DynamicNumberRange.splice(chkind, 1);
                        }

                        document.querySelectorAll('.checkbox').forEach(_radio => {
                            (<HTMLInputElement>_radio).checked = false;
                        });
                    }
                    else {

                    }
                });
            }
            else {

                Swal.fire({
                    title: 'Are you sure want to Delete ?',
                    showDenyButton: true,
                    confirmButtonText: 'YES',
                    cancelButtonText: 'NO',
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.updateID = 0;

                        this.ClientForm.value.NID = this.EdiNID;
                        /*this.ClientForm.value.TerminalID = this.TerminalID;*/

                        if (this.ClientForm.value.NID != "") {
                            this.sa.DeleteInstanceDtls(this.ClientForm.value).subscribe(Data => {
                                //this.ExistingvaluesBind(this.TerminalID);
                                //Swal.fire("Deleted Successfully");

                                Swal.fire({
                                    title: 'Deleted Successfully',
                                    showDenyButton: false,
                                    confirmButtonText: 'OK',
                                    allowOutsideClick: false,
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        $('#ddlInstanceType').val(0).trigger("change");
                                        $("#txtDescription").val("").trigger("change");
                                        $("#txtUrl").val("").trigger("change");
                                        $("#txtNoOfUsers").val("").trigger("change");
                                        $("#txtStartDate").val("").trigger("change");
                                        $("#txtEndDate").val("").trigger("change");
                                        $('#ddlActive').val(0).trigger("change");
                                        this.BindExistingRecords(this.CompanyID);
                                    } else {

                                    }
                                });
                            },
                                (error: HttpErrorResponse) => {
                                    Swal.fire(error.message)
                                });
                        }
                        else {
                            this.DynamicNumberRange.splice(chkind, 1);
                        }

                        document.querySelectorAll('.checkbox').forEach(_radio => {
                            (<HTMLInputElement>_radio).checked = false;
                        });

                    } else {

                    }
                });

            }

        }
    }



   

    OnSubmit() {

        var validation = "";


        var ddlLicenseType = $('#ddlLicenseType').val();
        if (ddlLicenseType == null) {
            validation += "<span style='color:red;'>*</span> <span>Please Select Licence Type</span></br>"
        }
        if ($('#txtClientNumber').val() == "") {
            validation += "<span style='color:red;'>*</span> <span>Please Enter Client Number</span></br>"
        }
        if ($('#txtLicenseNumber').val() == "") {
            validation += "<span style='color:red;'>*</span> <span>Please Enter Licence Number</span></br>"
        }

        if (this.DynamicNumberRange.length == 0) {
            validation += "<span style='color:red;'>*</span> <span>Please Fill Doc Details</span></br>"
        }
            

        for (var i = 0; i < this.DynamicNumberRange.length; i++) {


            if (this.DynamicNumberRange[i].TypeID == $('#ddlInstanceType').val()
                && (this.NID == null || this.NID != null)) {
                validation += "<span style='color:red;'>*</span> <span>Instance Type Already Exists</span></br>"
            }

        }

        if (validation != "") {
            Swal.fire(validation);
            return false;
        }

        if (($('#ddlInstanceType').val() == 0 || $('#ddlInstanceType').val() == null) && ($('#txtDescription').val() == "") && ($('#txtUrl').val() == "")
            && ($('#txtNoOfUsers').val() == "") && ($('#txtEndDate').val() == "") && ($('#txtStartDate').val() == "")
            && ($('#ddlActive').val() == 0 || $('#ddlActive').val() == null)) {
            this.updateID = 1;
            this.EditID = 0;
        }

       

        if (this.updateID == 0) {
            this.AddRecord();
        }
            
        

        if (this.EditID == 0) { 
        var ItemsDr = [];
        for (let item of this.DynamicNumberRange) {
            var intNID = 0;

            if (item.TypeID != 0) {
                if (typeof item.NID == "undefined")
                    intNID = 0;
                else
                    intNID = item.NID;

                ItemsDr.push('Insert:' + intNID, item.TypeID, item.Type, item.Description, item.Url, item.NoOfUsers, item.StartDate, item.EndDate, item.ActiveID, item.Active);
            }

        };
        this.ClientForm.value.ItemsV = ItemsDr.toString();



        //this.RangeForm.value.UserID = localStorage.getItem("UserID");
        //this.RangeForm.value.PageName = "NumberRange";
        this.ClientForm.value.EntityID = this.CompanyID;
        this.ClientForm.value.ClientNumber = $('#txtClientNumber').val();
        this.ClientForm.value.LicenseNumber = $('#txtLicenseNumber').val();
        this.ClientForm.value.LicenseType = $('#ddlLicenseType').val();
        this.ClientForm.value.Notes = $('#txtNotes').val();
        this.sa.saveLicense(this.ClientForm.value).subscribe(Data => {


            $('#ddlInstanceType').val(0).trigger("change");
            $("#txtDescription").val("").trigger("change");
            $("#txtUrl").val("").trigger("change");
            $("#txtNoOfUsers").val("").trigger("change");
            $("#txtStartDate").val("").trigger("change");
            $("#txtEndDate").val("").trigger("change");
            $('#ddlActive').val(0).trigger("change");
            Swal.fire("Record Saved SuccessFully");


            this.BindExistingRecords(this.CompanyID);

            this.ValidID = 0;
            this.updateID = 0;
            this.EditID == 0
        },

            (error: HttpErrorResponse) => {
                Swal.fire(error.message)
            });
    }
    }


    BindExistingRecords(idv) {


        this.ClientForm.value.EntityID = idv;

        this.sa.GetLicenseDtls(this.ClientForm.value).subscribe(data => {
            this.ClientForm.patchValue(data[0]);

            $('#ddlLicenseType').select2().val(data[0].LicenseType);

        },

            (error: HttpErrorResponse) => {
                Swal.fire(error.message)
            });

        this.sa.GetInstanceDtls(this.ClientForm.value).subscribe(data1 => {
            this.DynamicNumberRange.length = 0;
            if (data1.length > 0) {
                for (let item of data1) {

                    this.DynamicNumberRange.push({

                        'NID': item.NID,
                        'TypeID': item.TypeID,
                        'Type': item.Type,
                        'Description': item.Description,
                        'Url': item.Url,
                        'NoOfUsers': item.NoOfUsers,
                        'StartDate': item.StartDate,
                        'EndDate': item.EndDate,
                        'ActiveID': item.ActiveID,
                        'Active': item.Active,
                    });
                }
            }
            else {

            }
        });


    }

    btntabclick(tab) {


        var values = "ID: " + this.CompanyID;
     
        //var values = "ID: 8";
        var encrypted = this.ES.set(localStorage.getItem("EncKey"), values);
        if (tab == 1) {

            this.router.navigate(['/views/masters/instanceprofile/companydetails/companydetails'], { queryParams: { encrypted } });
        }
        else if (tab == 2) {

            this.router.navigate(['/views/masters/instanceprofile/clientmanagement/clientmanagement'], { queryParams: { encrypted } });

        }
        else if (tab == 3) {

            this.router.navigate(['/views/masters/instanceprofile/configuration/configuration'], { queryParams: { encrypted } });

        }
        else if (tab == 4) {

            this.router.navigate(['/views/instanceprofile/security/security'], { queryParams: { encrypted } });

        }

    }

}
interface Status {
    value: string;
    viewValue: string;
}
